import { mapActions, mapGetters } from 'vuex'
import { camelCase, snakeCase } from 'lodash'

import validationMixin from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'

import SelectPackages from '@/components/forms/SelectPackages';

export default {
  name: 'courses-contents-studying-modules-view',
  mixins: [validationMixin],
  data () {
    return {
      dialog: true,
      valid: false,
      payload: {
        courseId: this.$route.params.course_id,
        name: '',
        title: '',
        slug: '',
        description: '',
        image: '',
        picture: '',
        statusId: '',
        sort: '',
        packages: []
      }
    }
  },
  components: {
    MainEditor,
    SlugNameInput,
    ImageFile,
	SelectPackages
  },
  computed: {
    ...mapGetters({
      packagesList: 'coursesPackages/list',
      setting: 'courses/setting',
      currentCourse: 'coursesInfo/info',
      moduleLoading: 'modules/moduleLoading',
      currentItem: 'modules/currentItem',
      updateImageLoading: 'modules/updateImageLoading'
    })
  },
  created () {
    if (this.currentItem) {
      this.setContent()
    }
  },
  methods: {
    ...mapActions({
      create: 'modules/CREATE_MODULE',
      update: 'modules/UPDATE_MODULE',
      updateImage: 'modules/MODULE_UPDATE_IMAGE'
    }),

    updateImageLocal (params) {
      params.append('course_id', this.$route.params.course_id)
      params.append('module_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    setContent () {
      Object.entries(this.currentItem).forEach(e => {
        this.payload[camelCase(e[0])] = e[1]
      })
      this.payload.packages = this.payload.packages.data.map(e => e.id)
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}
      Object.keys(data).forEach(e => {
        formData[snakeCase(e)] = data[e]
      })
      if (this.currentItem) {
        formData.id = this.currentItem.id
      }
      return formData
    },
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (!this.currentItem) {
          this.create(this.createRequestPayload()).then((response) => {
            this.$toasted.success(this.$t('moduleSuccessCreate'))
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('moduleSuccessUpdate'))
            this.changeCloseDialog()
          })
        }
      }
    },
    resetPayload () {
      Object.keys(this.payload).forEach(e => {
        if (e !== 'course_id') {
          this.payload[e] = ''
        }
      })
    }
  }
}
