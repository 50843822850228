export function courseContentStudyingModulesTableHead () {
  return [
    {
        text: '',
        value: 'dragg',
		sortable: false
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Picture',
      value: 'picture'
    },
    {
      text: 'Created time',
      value: 'created_at'
    },
    {
      text: 'Packages',
      value: 'packages'
    },

    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
