import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'

import CourseViewDialog from '../view/index.vue'
import Draggable from 'vuedraggable'

import { courseContentStudyingModulesTableHead } from '@/modules/courses/helpers/content-styding-modules'

export default {
  name: 'courses-contents-studying-modules-main',
  mixin: [mixins],
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      modules: []
    }
  },
  components: {
    CourseViewDialog,
    Draggable
  },
  watch: {
    'list' () {
      this.modules = this.list
    }
  },
  computed: {
    ...mapGetters({
      list: 'modules/list',
      loading: 'modules/listLoading',
      removeListItemLoading: 'userAdmins/removeListItemLoading',
      moduleLoading: 'modules/moduleLoading',
      packagesList: 'coursesPackages/list'
    }),
    courseContentStudyingModulesTableHead
  },
  created () {
    if (this.list.length <= 0) {
      this.fetchList({ course_id: this.$route.params.course_id }).then(() => {
        this.modules = this.list
      })
    } else {
      this.modules = this.list
    }

    if (this.packagesList.length <= 0) {
      this.fetchPackages({ id: this.$route.params.course_id })
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'modules/GET_LIST',
      removeListItem: 'modules/DELETE_MODULE',
      updateSort: 'modules/UPDATE_SORT',
      fetchPackages: 'coursesPackages/PACKAGES_GET_LIST'
    }),
    ...mapMutations({
      changeCurrentItem: 'modules/SET_ITEM'
    }),
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeDialogView (item = null) {
      this.changeCurrentItem(item)
      this.openDialogView = true
    },
    removeModule () {
      this.removeListItem(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true

      item.course_id = this.$route.params.course_id
      this.removingItem = item
    },
    changeSortModules: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.modules.map(e => e.id)
        const formData = {
          course_id: this.$route.params.course_id,
          modules: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000),

	  onChangePosition(target, index){
        let list = this.modules;
        let element = list[index];

        if (target === 'up'){
			this.modules.splice(index, 1);
			this.modules.splice(index - 1, 0, element);

        } else if (target === 'down'){
			this.modules.splice(index, 1);
			this.modules.splice(index + 1, 0, element);
        }

        this.changeSortModules({moved: true});
      }
  }
}
